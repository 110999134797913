import React from "react";
import {
    Grid,
    Text
} from '@mantine/core'
import "@fontsource/montserrat"
import ContactForm from "../contact/ContactForm";
import ExperienceSection from "../experience/Experience";
import HeroSection from "../hero/Hero";
import FloatingNav from "../nav/nav";






const LandingPage = () => {

    return (
       

        <div className="justify-center w-full background-image" >
            <FloatingNav />
             



            <div className="pt-4">
            <container id="hero" className="">
                <HeroSection />
                </container>
            </div>

            <div className="justify-center pt-60 w-full">
                <container id="work" className="text-center">
                    {/* <h2 className="font-bold text-3xl md:text-5xl lg:text-5xl text-primary-blue">Experience</h2>
                    <h5 className="pt-3 text-1xl md:text-2xl lg:text-2xl">Skills</h5> */}
                    {/* <Text className="text-1xl text-primary-blue opacity-40 flex-auto">I am comfortable with a wide variety of technologies.</Text> */}

                    <ExperienceSection />
                </container>
            </div>


            <div className="justify-center mt-32 mb-32 w-full">
                <container>
                
                    {/* <Text className="font-bold text-3xl md:text-5xl lg:text-5xl text-primary-blue">Contact</Text> */}
                    <ContactForm />
                </container>
            </div>

        </div>


       

    )

}

export default LandingPage