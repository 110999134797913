import React from "react";
import { Link } from "gatsby";
import {
    Grid,
    Text,
    Button,
    Image,
} from '@mantine/core'
import "@fontsource/montserrat"
import { Linkedin } from "react-feather";
import { Send } from "react-feather";
import { Gitlab } from "react-feather";
// import { GitHub } from "react-feather";
// import { Twitter } from "react-feather";
import { Tooltip } from "@mantine/core";
import './hero.css'
import { StaticImage } from "gatsby-plugin-image";




const HeroSection = () => {

    return (
        <div className="text-center justify-center pt-4 lg:pt-24 pb-32">
            
            <h1 class="text-primary-blue font-bold">Hi, I'm</h1>
            <h1 class="text-5xl py-3">Zach Bohl<br /></h1>
            <h1 class="">Fullstack Developer - Network Security Engineer</h1>
            <div className="pt-4">
                <StaticImage
                    src="../../images/picture.png"
                    alt="Picture of me"
                    placeholder="blurred"
                    width={300}
                    height={300}
                />
            </div>

            
                {/* <div className="flex flex-wrap lg:ml-20 justify-left md:justify-start max-w-xl mt-0 md:my-36"> */}


                    <Text className="pt-5 opacity-50 text-1xl md:text-2xl">I'm a DevOps focused network security engineer and developer. Currently, I specialize in building self service and automation tooling for network, security and cloud infrastructure.</Text>

                    {/* <div className="w-full flex pt-10 md:pl-2 justify-center md:justify-start">
                            <Link to="#work">
                                <Button
                                    variant="outline"
                                    size="lg"
                                    className="">
                                    Check out my work.
                                </Button>
                            </Link>
                        </div> */}



                {/* </div> */}


                {/* <nav className="hidden md:mr-24 lg:w-4 fixed left-percentage bottom-percentage xl:block">
                    <div className="absolute left-10 transform -translate-x-1/2 space-y-6 mt-40">
                        <Link to="https://www.linkedin.com/in/zacharybohl" target="_blank"><Tooltip label="Linkedin" position="right"><Linkedin className=" text-primary-blue" /></Tooltip></Link>
                        <Link to="mailto:zach.bohl@gmail.com"><Tooltip label="Email" position="right"><Send className=" text-primary-blue" /></Tooltip></Link>
                        <Link to="https://gitlab.com/zachbohl" target="_blank"><Tooltip label="Gitlab" position="right"><Gitlab className=" text-primary-blue" /></Tooltip></Link>
                        <Link to="https://linkedin.com" target="_blank"><Twitter className=" text-primary-blue"/></Link>

                    </div>

                </nav> */}
           
        </div>






    )

}

export default HeroSection