import React from 'react'
import { 
 } from '@mantine/core'
 import LandingPage from '../components/landing/Landing'
import FloatingNav from '../components/nav/nav'
// import './index.css'
import { SEO } from "../components/seo"

const indexPage = () => {
return (
  
  
   
    <LandingPage />
    
    
   
    
)
}

export default indexPage

export const Head = () => (
    <SEO />
  )