import React from "react";
import {
    Grid,
    Badge,
    Card,
    Text,
    Group,
    SimpleGrid
} from '@mantine/core'
import "@fontsource/montserrat"
import { CheckCircle } from "react-feather";
import './experience.css'





const ExperienceSection = () => {

    return (
        <div className="justify-center lg:pb-64">
            <div className="w-full justify-center text-center pb-6">
                <h2 className="font-bold text-3xl md:text-5xl lg:text-5xl text-primary-blue w-full">Experience</h2>
                <h5 className="pt-3 text-1xl md:text-2xl lg:text-2xl">Skills</h5>
            </div>



            {/* <h3 className="font-bold text-1xl md:text-1xl lg:text-1xl text-left md:text-left">Technologies</h3> */}
            {/* <p class="section-paragraph text-2xl">I am comfortable with a wide variety of technologies.</p> */}

            {/* <Text className="font-bold text-3xl md:text-5xl lg:text-5xl text-primary-blue">Experience</Text> */}

            <div className="text-center justify-center md:px-16 lg:px-64 skills-container">
                <SimpleGrid
                    className="justify-content-between"
                    cols={3}
                    spacing="xs"
                    breakpoints={[
                        { maxWidth: 1025, cols: 3, spacing: 'lg' },
                        { maxWidth: 1024, cols: 2, spacing: 'sm' },
                        { maxWidth: 600, cols: 1, spacing: 'sm' },
                    ]}
                >
                    <Card shadow="sm" p="xs" radius="lg" withBoarder>
                        <div justify="center" py={6}>
                            <Text class="text-white font-semibold space-y-2 flex">Infrastructure</Text>
                        </div>
                        <div grow align="center" p="lg">
                            <SimpleGrid cols={2}>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Cisco ASA</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Palo Alto</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Cisco IOS</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Cisco Nexus</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Cisco Wireless</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>AWS</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>OSPF</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>BGP</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>EIGRP</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Kubernetes</Text></Group>
                            </SimpleGrid>
                        </div>
                    </Card>


                    {/* <p class="text-secondary">Focus on network security, front-end and back-end development for self-service, autmoation, and Network-as-Code.</p> */}




                    <Card shadow="sm" p="lg" radius="lg" withBoarder>
                        <div justify="center" py={6}>
                            <Text class="text-white font-semibold space-y-2 flex">Front-end Development</Text>
                        </div>
                        <div grow align="center" p="lg">
                            <SimpleGrid cols={2} justify>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Javascript</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>HTML</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>CSS</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>React</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Vue</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Material UI</Text></Group>

                            </SimpleGrid>
                        </div>
                    </Card>




                    {/* <p class="text-secondary">Experience developing full-stack production web applications.</p> */}



                    <Card shadow="sm" p="lg" radius="lg" withBoarder>
                        <div justify="center" py={6}>
                            <Text class="text-white font-semibold space-y-2 flex">Back-end Development</Text>
                        </div>
                        <div grow align="center" p="lg">
                            <SimpleGrid cols={2} justify>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Python</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>flask</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>fastAPI</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Go</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>MongoDB</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>SQL</Text></Group>
                                <Group noWrap><CheckCircle className="text-primary-blue" size={16} /><Text>Redis</Text></Group>
                            </SimpleGrid>
                        </div>
                    </Card>
                </SimpleGrid>

            </div>
            <div className="w-full justify-center">
            <p class="text-center text-2xl px-8 md:px-32 py-8">I have had the pleasure of working on some amazing teams.</p>
            </div>


            <container className="teams-container lg:px-32">
                <div className="team">
                    <div className="team-header">
                        <h3>American Airlines</h3>
                    </div>
                    <div className="team-content">
                        <div className="w-full flex flex-wrap justify-center gap-y-2 gap-x-2 p-10">
                            <Badge>Network Security</Badge>
                            <Badge>Full-Stack Development</Badge>
                            <Badge>DevOps</Badge>
                        </div>
                        <p>Focus on network security, front-end and back-end development for self-service, automation, and Network-as-Code.</p>
                    </div>
                </div>
                <div className="team">
                    <div className="team-header">
                        <h3>Gamestop</h3>
                    </div>
                    <div className="team-content">
                        <div className="w-full flex flex-wrap justify-center gap-y-2 gap-x-2 p-10">
                            <Badge>Network Security</Badge>
                            <Badge>WiFi Engineering</Badge>
                            <Badge>Routing and Switching</Badge>
                            <Badge>Network Access Control</Badge>
                        </div>
                        <p>Focus on network routing/switching, high density wifi design, and security.</p>

                    </div>
                </div>
                <div className="team">
                    <div className="team-header">
                        <h3>Dallas Cowboys</h3>
                    </div>
                    <div className="team-content">
                        <div className="w-full flex flex-wrap justify-center gap-y-2 gap-x-2 p-10">
                            <Badge>Network Security</Badge>
                            <Badge>High Density Stadium WiFi</Badge>
                            <Badge>Routing and Switching</Badge>
                        </div>
                        <p>Focus on high density stadium wifi and routing/switching.</p>

                    </div>
                </div>

            </container>

            







        </div>



    )

}

export default ExperienceSection