import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Input, Textarea, Button } from "@mantine/core";
import { Grid, Text } from "@mantine/core";


export default function ContactForm() {
  const [state, handleSubmit] = useForm("mnqrqdyw");

  if (state.succeeded) {
    return <p className="flex w-full">Thanks for your submission!</p>;
  }

  return (
    <div className="w-full justify-center px-8 md:px-64 lg:pb-64">
      <h1 id="contact" className="text-center font-bold text-2xl md:text-2xl lg:text-2xl md:text-left text-primary-blue">Contact</h1>
      
        
          
          
          <form onSubmit={handleSubmit}>
            <div className="py-3">
              <label htmlFor="full-name">Full Name</label>
              <Input id="full-name" type="full-name" name="name" />
              <ValidationError prefix="full-name" field="full-name" errors={state.errors} />
            </div>
            <div className="py-3">
              <label htmlFor="email">Email Address</label>
              <Input id="email" type="email" name="email" />
              <ValidationError prefix="Email" field="email" errors={state.errors} />
            </div>
            <div className="py-3">
              <label htmlFor="message">Message</label>
              <Textarea id="message" name="message" />
              <ValidationError prefix="Message" field="message" errors={state.errors} />
            </div>
            <div className="py-3">
              <Button variant="outline" type="submit" disabled={state.submitting}>
                Submit
              </Button>
              <ValidationError errors={state.errors} />
            </div>
          </form>
        
      
    </div>
  );
}
